<template>
  <div class="article-config reset-btn-page" v-loading="loading">
      <h2 class="title">发布设置</h2>
      <el-form size="medium" :model="config" :rules="rules"
               label-position="right" label-width="70px"
               class="form small-form">
          <el-form-item label="审核设置：">
              <el-radio-group v-model="config.post_check_enable">
                  <el-radio :label="1">开启</el-radio>
                  <el-radio :label="0">关闭</el-radio>
              </el-radio-group>
              <div class="form-tip">
                  <span class="el-icon-info" style="font-size: 15px;color:#C0C4CC" />
                  <span>关闭后，文章管理下审核列表的文章将自动转移到发布列表</span>
              </div>
          </el-form-item>
      </el-form>
    <h2 class="title">评论设置</h2>
    <el-form size="medium" :model="config" :rules="rules"
             label-position="right" label-width="70px"
      class="form small-form">
      <el-form-item label="审核设置：">
        <el-radio-group v-model="config.comment_check_type">
          <el-radio :label="1">先发后审</el-radio>
          <el-radio :label="0">先审后发</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="评论间隔：">
        <el-input type="text" v-model="config.interval" placeholder="请输入间隔"></el-input>
        <span class="input-suffix">秒</span>
      </el-form-item>
      <el-form-item label="过滤词库：">
        <el-radio-group v-model="config.is_open_filter_word">
          <el-radio :label="1">开启</el-radio>
          <el-radio :label="0">关闭</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="选择词库：" v-if="config.is_open_filter_word">
        <el-radio-group v-model="isSome">
          <el-radio :label="false">全部</el-radio>
          <el-radio :label="true">部分</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="" v-if="isSome && config.is_open_filter_word">
        <el-select v-model="config.filter_words" placeholder="请选择词库" multiple>
          <el-option v-for="item in all_filter_words" :key="item.id" :label="item.title" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <h2 class="title">数据展示设置</h2>
    <el-form size="medium" :model="config" :rules="rules"
             label-position="right" label-width="70px"
      class="form small-form">
      <el-form-item label="阅读量：">
        <el-radio-group v-model="config.read_count_status">
          <el-radio :label="1">显示</el-radio>
          <el-radio :label="0">隐藏</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <FixedActionBar>
      <el-button type="primary" @click="saveConfig">保存</el-button>
      <el-button @click="cancel">取消</el-button>
    </FixedActionBar>
  </div>
</template>

<script>
  import {getConfig, getFilterWords, saveConfig} from "../api/article-config";
  import FixedActionBar from "../../../base/layout/FixedActionBar";

  export default {
  components: {FixedActionBar },
  data() {
    return {
      loading: false,
      config: {},
      rules: {},
      all_filter_words: [],
      isSome: false,
    };
  },
  created() {
    this.getConfig();
    this.getFilterWords();
  },
  watch: {
    isSome: {
      handler(val) {
        if (!val) {
          this.config.filter_words = [];
        }
      },
    },
  },
  methods: {
    getConfig() {
      this.loading = true;
      getConfig()
        .then((res) => {
          this.config = res.data || {};
          this.isSome = res.data.filter_words.length > 0;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    getFilterWords() {
      getFilterWords()
        .then((res) => {
          this.all_filter_words = res.data;
        })
        .catch((err) => {});
    },
    saveConfig() {
      console.log(this.config.interval);
      if (this.config.interval == "") {
        return this.$message.success("评论间隔不能为空");
      }
      if (this.config.interval == 0) {
        return this.$message.success("评论间隔不能为0");
      }
      console.log(this.config.filter_words);
      if (this.isSome == true && !this.config.filter_words.length) {
        return this.$message.success("词语库不能为空");
      }
      this.loading = true;

      saveConfig({
        ...this.config,
      })
        .then((res) => {
          this.$message.success(res.msg);
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    cancel() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
.article-config {
  .title {
    padding-left: 8px;
    font-size: 16px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.85);
    line-height: 1;
    border-left: 3px solid #3479ef;
    margin-bottom: 32px;
  }

  .el-input {
    margin-right: 10px;
  }

  .el-select {
    width: 295px;
  }
}

.form-tip {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #606266;
  line-height: 12px;
  margin-top: 15px;
  span + span {
    margin-left: 4px;
  }

}
</style>
