import api from "@/base/utils/request";

export const getConfig = data => {
  return api({
    url: '/admin/portal/article/getConfig',
    method: 'post',
    data
  })
}

export const getFilterWords = data => {
  return api({
    url: '/admin/portal/article/allWords',
    method: 'post',
    data
  })
}

export const saveConfig = data => {
  return api({
    url: '/admin/portal/article/saveConfig',
    method: 'post',
    data
  })
}
